import { Fragment } from 'react';
import classnames from 'classnames';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import Subtitle from 'src/pages/AnonymousEmployer/components/Subtitle';

const TrlKeys = {
    1: {
        title: 'index.employer.step.1.title',
    },
    2: {
        title: 'index.employer.step.2.title',
    },
    3: {
        title: 'index.employer.step.3.title',
    },
};

const STEPS = [1, 2, 3] as const;

const StepsZarplata: TranslatedComponent = ({ trls }) => {
    return (
        <>
            {STEPS.map((step) => (
                <Fragment key={step}>
                    <Column xs="0" s="0" m="0" l="1" />
                    <Column xs="4" s="8" m="4" l="4">
                        <div
                            className={classnames('employer-index-step', {
                                'employer-index-step_arrow-zarplata': step !== STEPS.length,
                            })}
                        >
                            <div
                                className={classnames(
                                    'employer-index-step-img',
                                    `employer-index-step-img-zarplata_${step}`
                                )}
                            />
                            <Gap lTop mTop sLeft>
                                <Subtitle>{trls[TrlKeys[step].title]}</Subtitle>
                            </Gap>
                        </div>
                    </Column>
                </Fragment>
            ))}
        </>
    );
};

export default translation(StepsZarplata);
