import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './title.less';

const Title: FC<
    {
        oneline?: boolean;
        center?: boolean;
    } & PropsWithChildren
> = ({ oneline = false, center = false, children }) => {
    return (
        <div
            className={classnames(styles.title, {
                [styles.oneLine]: oneline,
                [styles.center]: center,
            })}
        >
            {children}
        </div>
    );
};

export default Title;
