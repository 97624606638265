import Conversion from 'bloko/blocks/conversion';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    employersOnline: 'index.employer.employersOnline',
    employersOne: 'index.employer.employersOnline.one',
    employersSome: 'index.employer.employersOnline.some',
    employersMany: 'index.employer.employersOnline.many',
};

interface EmployersOnlineProps {
    value: number;
}

const EmployersOnline: TranslatedComponent<EmployersOnlineProps> = ({ value, trls }) => {
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    return (
        <div className="employer-index-step-companies-online">
            <span className="employer-index-step-companies-online-pin" />
            <Text size={isXs ? TextSize.Medium : TextSize.Large} importance={TextImportance.Secondary}>
                {formatToReactComponent(trls[TrlKeys.employersOnline], {
                    '{0}': (
                        <Text
                            Element="span"
                            size={isXs ? TextSize.Medium : TextSize.Large}
                            importance={TextImportance.Secondary}
                            strong
                        >
                            <Conversion
                                value={value}
                                one={trls[TrlKeys.employersOne]}
                                some={trls[TrlKeys.employersSome]}
                                many={trls[TrlKeys.employersMany]}
                            />
                        </Text>
                    ),
                })}
            </Text>
        </div>
    );
};

export default translation(EmployersOnline);
