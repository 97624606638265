import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton/index';
import IndexSection, { HighlightType } from 'src/pages/AnonymousEmployer/components/Section';
import Title from 'src/pages/AnonymousEmployer/components/Title';

import EmployersOnline from 'src/pages/AnonymousEmployer/components/SearchSteps/EmployersOnline';
import Steps from 'src/pages/AnonymousEmployer/components/SearchSteps/Steps';
import StepsZarplata from 'src/pages/AnonymousEmployer/components/SearchSteps/StepsZarplata';

interface SearchStepsProps {
    isRegularHighlight?: boolean;
}

const TrlKeys = {
    title: 'index.employer.steps.title',
    start: 'index.employer.steps.start',
};

const SearchSteps: TranslatedComponent<SearchStepsProps> = ({ trls, isRegularHighlight }) => {
    const averageEmployersOnline = useSelector(({ anonymousEmployer }) => anonymousEmployer.averageEmployersOnline);
    const sendAnalytics = () => {
        Analytics.sendHHEventButtonClick('start_recruiting');
    };
    const isHhru = useIsHhru();
    const isZP = useIsZarplataPlatform();

    return (
        <IndexSection highlight={isRegularHighlight ? HighlightType.Regular : HighlightType.Light}>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    <Title oneline={!isZP} center={isZP}>
                        {formatNewLine(trls[TrlKeys.title])}
                    </Title>
                    <VSpacing base={8} />
                </Column>
                {isZP ? <StepsZarplata /> : <Steps />}
                <Column xs="0" s="0" m="0" l="1" />
                <Column xs="4" s="8" m="12" l="16">
                    <div className="employer-index-step-action">
                        <LandingButton
                            Element={Link}
                            data-qa="employer-index-start_recruiting"
                            to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                            onClick={sendAnalytics}
                            outlined={!isZP}
                        >
                            <span className="employer-index-action-label">{trls[TrlKeys.start]}</span>
                        </LandingButton>
                        {isHhru && averageEmployersOnline && <EmployersOnline value={averageEmployersOnline} />}
                    </div>
                </Column>
            </ColumnsWrapper>
        </IndexSection>
    );
};

export default translation(SearchSteps);
