import { FC } from 'react';
import classnames from 'classnames';

import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import styles from './styles.less';

const ApplicationCard: FC<{ type: CardType }> = ({ type }) => {
    return (
        <div
            className={classnames({
                [styles.containerVacancies]: type === CardType.Vacancies,
                [styles.containerCandidates]: type === CardType.Candidates,
            })}
        >
            <div className={styles.cardContainer}>
                <div className={classnames({ [styles.cardContent]: type === CardType.Vacancies })}>
                    <div
                        className={classnames({
                            [styles.appCardImageVacancy]: type === CardType.Vacancies,
                            [styles.appCardImageResume]: type === CardType.Candidates,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default ApplicationCard;
