import { FC, PropsWithChildren, useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Text, Card as MagritteCard, AvatarIconStyle, useBreakpoint, Tag } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import VSpacing from 'bloko/blocks/vSpacing';

import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import ApplicationCard from 'src/pages/AnonymousEmployer/components/Price/CardRu/ApplicationCard';
import List from 'src/pages/AnonymousEmployer/components/Price/CardRu/List';
import ListItem from 'src/pages/AnonymousEmployer/components/Price/CardRu/ListItem';

import styles from './card.less';

export interface CartOption {
    icon: JSX.Element;
    style: AvatarIconStyle;
    text: string;
}

interface CardProps {
    type: CardType;
    header: string;
    action: string;
    toLink: string;
    clickEvent: string;
    description: string;
    cartOptions: CartOption[];
    withTopTag?: boolean;
}

const Card: FC<CardProps & PropsWithChildren> = ({
    type,
    header,
    action,
    toLink,
    clickEvent,
    description,
    cartOptions,
    withTopTag,
}) => {
    const sendDV360Analytics = useDV360Analytics();

    const handleClick = () => {
        Analytics.sendHHEventButtonClick(clickEvent);
        if (type === CardType.Candidates) {
            sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_PRICE_CANDIDATES_BUTTON_CLICK');
        }
    };
    const { isXS } = useBreakpoint();
    const tagContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tagContainerRef?.current?.offsetWidth && tagContainerRef?.current?.offsetHeight) {
            tagContainerRef.current.style.visibility = 'visible';
            tagContainerRef.current.style.left = `calc(50% - ${tagContainerRef.current.offsetWidth}px / 2)`;
            tagContainerRef.current.style.top = `calc(-${tagContainerRef.current.offsetHeight}px / 2)`;
        }
    }, []);

    return (
        <div className={styles.container}>
            {withTopTag && (
                <div className={styles.topTag} ref={tagContainerRef}>
                    <Tag style="positive" size={isXS ? 'medium' : 'large'}>
                        Часто выбирают
                    </Tag>
                </div>
            )}
            <MagritteCard padding={isXS ? 16 : 24} borderRadius={isXS ? 24 : 32} showBorder>
                <div className={styles.cardContentContainer}>
                    <ApplicationCard type={type} />
                    <VSpacing base={6} xs={4} />
                    <Text typography="title-5-semibold">{header}</Text>
                    <VSpacing base={1} />
                    <Text typography="label-3-regular" style="secondary">
                        {description}
                    </Text>
                    <VSpacing base={6} />
                    <List>
                        {cartOptions.map(({ icon, text, style }) => (
                            <ListItem key={text} text={text} icon={icon} style={style} />
                        ))}
                    </List>
                    <VSpacing base={7} xs={6} />
                    <Button
                        data-qa={`${type}-price-card-action`}
                        Element={Link}
                        to={toLink}
                        kind={ButtonKind.Primary}
                        stretched
                        onClick={handleClick}
                    >
                        {action}
                    </Button>
                </div>
            </MagritteCard>
        </div>
    );
};

export default Card;
