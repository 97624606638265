import { ChangeEvent, useCallback, useRef, RefObject, ReactNode } from 'react';

import { Tip } from 'bloko/blocks/drop';
import SuggestPickerItems from 'bloko/blocks/suggest/SuggestPickerItems';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { INPUT_ID } from 'src/components/A11y/A11yConstants';
import SmartSuggest from 'src/components/SupernovaSearch/SmartSuggest';
import useSendSuggestAnalytics from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

import styles from './search.less';

const WILDCARD = '%QUERY%';
const RESUME_REMOTE = `/vacancysuggest/?q=${WILDCARD}`;
const TIP_TIMEOUT = 2000;
const MAX_LENGTH = 3000;
const MAX_SUGGEST_LINES = 12;
const SUGGEST_UPDATE_DELAY = 50;

const TrlKeys = {
    moreThanMax: 'query.length.moreThanMax',
    placeholder: 'index.employer.search.placeholder.resume',
    submit: 'supernova.search.submit',
};

type SuggestType = {
    text: string;
};

interface SearchInputProps {
    innerRef: RefObject<HTMLInputElement>;
    onSuggestPick: (suggest: SuggestType) => void;
}

const SearchInput: TranslatedComponent<SearchInputProps> = ({ trls, innerRef, onSuggestPick }) => {
    const [visible, show, hide] = useOnOffState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics } = useSendSuggestAnalytics();

    const pickValue = (suggest: SuggestType): void => {
        if (innerRef.current) {
            innerRef.current.value = suggest.text;
        }
        onSuggestPick(suggest);
    };

    const trackChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value?.length === MAX_LENGTH) {
            show();
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                hide();
            }, TIP_TIMEOUT);
            return;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        visible && hide();
    };

    const dataProvider = createRemoteDataProvider(RESUME_REMOTE, WILDCARD);

    const onSmartSuggestChange = (item: DataProviderItem) => {
        if (item) {
            sendSuggestItemClickAnalytics(item);
        }
        setTimeout(() => pickValue(item), 0);
    };

    const onDesktopSuggestShown = useCallback(
        (node: HTMLDivElement | null) => {
            if (node) {
                sendSuggestShownAnalytics();
            }
        },
        [sendSuggestShownAnalytics]
    );

    return (
        <div className={styles.inputWrapper}>
            <Tip
                show={visible}
                onExternalClose={hide}
                render={() => trls[TrlKeys.moreThanMax]}
                data-qa="employer-index-search-input"
            >
                <SmartSuggest
                    autoHighlightFirstSuggest={false}
                    selectOnBlur={false}
                    dataProvider={dataProvider}
                    itemKey={(suggest: DataProviderItem) => suggest.text}
                    limit={MAX_SUGGEST_LINES}
                    debounceDelay={SUGGEST_UPDATE_DELAY}
                    onChange={onSmartSuggestChange}
                    // setTimeout нужен, чтобы значение инпута обновилось после закрытия модалки
                    onSubmit={(text) => setTimeout(() => pickValue({ text }), 0)}
                    renderItems={(items, renderItem: (item: DataProviderItem, index: number) => ReactNode) => (
                        <div ref={onDesktopSuggestShown}>
                            <SuggestPickerItems>
                                {items.map((item, index) => renderItem(item, index))}
                            </SuggestPickerItems>
                        </div>
                    )}
                    inputValue={innerRef.current?.value || ''}
                    submitButtonTitle={trls[TrlKeys.submit]}
                    mobileInputProps={{
                        id: INPUT_ID,
                        autoComplete: 'off',
                        'aria-label': trls[TrlKeys.submit],
                        'data-qa': 'search-input',
                    }}
                    onMobileSuggestShown={sendSuggestShownAnalytics}
                >
                    <input
                        ref={innerRef}
                        className={styles.searchInput}
                        onChange={trackChange}
                        autoComplete="off"
                        type="text"
                        name="text"
                        maxLength={MAX_LENGTH}
                        placeholder={trls[TrlKeys.placeholder]}
                        data-qa="employer-index-search-input"
                    />
                </SmartSuggest>
            </Tip>
        </div>
    );
};

export default translation(SearchInput);
