import Link, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsMiddleAsia } from 'src/hooks/useSites';
import { ContactPhoneItem } from 'src/models/contactPhones';

import styles from './support.less';

const Features = {
    supportEmails: 'asian_countries_support_emails',
};

const TrlKeys = {
    msk: 'index.employer.support.postfix.msk',
    spb: 'index.employer.support.postfix.spb',
    regions: 'index.employer.support.postfix.regions',
    city: 'index.employer.support.postfix.city',
    mobile: 'index.employer.support.postfix.mobile',
    other: 'index.employer.support.postfix.other',
};

const Contact: TranslatedComponent<ContactPhoneItem> = ({ trls, country, city, number, postfix }) => {
    const phoneFull = `${country} ${city} ${number}`;

    const supportEmails = useSelector(({ features }) => features[Features.supportEmails] as string);
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain);
    const isMiddleAsia = useIsMiddleAsia();
    let supportEmail = null;
    try {
        supportEmail = topLevelDomain && (JSON.parse(supportEmails) as Record<string, string>)?.[topLevelDomain];
    } catch (e) {
        console.error(e);
    }

    if (isMiddleAsia) {
        return (
            <div className={styles.contact} data-qa="employer-index-support-contact">
                <div className={styles.phone}>
                    <Link kind={LinkKind.Tertiary} href={`tel:${phoneFull}`}>
                        {phoneFull}
                    </Link>
                </div>
                <VSpacing base={3} xs={1} />
                {supportEmail && (
                    <Text importance={TextImportance.Secondary} size={TextSize.ExtraLarge}>
                        {supportEmail}
                    </Text>
                )}
            </div>
        );
    }

    return (
        <div className={styles.contact} data-qa="employer-index-support-contact">
            <div className={styles.phone}>
                <Link kind={LinkKind.Tertiary} href={`tel:${phoneFull}`}>
                    {phoneFull}
                </Link>
            </div>
            <VSpacing base={3} xs={1} />
            <Text importance={TextImportance.Secondary} size={TextSize.ExtraLarge}>
                {trls[TrlKeys[postfix as keyof typeof TrlKeys]]}
            </Text>
        </div>
    );
};

export default translation(Contact);
