import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './section.less';

export enum HighlightType {
    Light = 'light',
    Regular = 'regular',
}

const Section: FC<
    {
        highlight?: HighlightType;
    } & PropsWithChildren
> = ({ children, highlight }) => {
    return (
        <div
            className={classnames(styles.section, {
                [styles.light]: highlight === HighlightType.Light,
                [styles.regular]: highlight === HighlightType.Regular,
            })}
        >
            {children}
        </div>
    );
};

export default Section;
