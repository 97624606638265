import { FC } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import Vacancy from 'src/pages/AnonymousEmployer/components/PublishVacancy/Vacancy';

import styles from './publish-vacancy.less';

const PublishVacancy: FC = () => {
    const isZarplata = useIsZarplataPlatform();
    return (
        <div
            className={classnames(styles.wrapper, {
                [styles.wrapperWide]: isZarplata,
            })}
        >
            <ColumnsWrapper>
                <Column xs="0" s="1" m="1" l="3" />
                <Vacancy />
            </ColumnsWrapper>
        </div>
    );
};

export default PublishVacancy;
