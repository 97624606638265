import classnames from 'classnames';

import { ButtonType } from 'bloko/blocks/button';
import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

interface LandingButtonProps {
    angular?: boolean;
    outlined?: boolean;
    Element?: 'a' | 'button';
    type?: ButtonType;
}

const LandingButton: ComponentWithCustomElement<LandingButtonProps, 'button'> = ({
    children,
    Element = 'button',
    type = ButtonType.Button,
    outlined = false,
    angular = false,
    ...rest
}) => {
    const typeAttribute: { type?: ButtonType } = {};
    const isZarplata = useIsZarplataPlatform();

    if (Element === 'button') {
        typeAttribute.type = type;
    }

    return (
        <Element
            className={classnames('employer-index-button', {
                'employer-index-button_zarplata': isZarplata,
                'employer-index-button_appearance-outlined': outlined,
                'employer-index-button_angular-left-side': angular,
            })}
            {...typeAttribute}
            {...rest}
        >
            {children}
        </Element>
    );
};

export default LandingButton;
