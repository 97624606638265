import classnames from 'classnames';

import Column from 'bloko/blocks/column';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import Contact from 'src/pages/AnonymousEmployer/components/Support/Contact';

import styles from './support.less';

declare global {
    interface Window {
        /** чат для hr.zarplata.ru https://developer.zendesk.com/api-reference/widget/core/#open */
        zE: (widget: string, action: string) => void;
    }
}

const SHORT_LIST_THRESHOLD = 3;

const TrlKeys = {
    title: 'index.employer.support.title',
    subtitle: 'index.employer.support.subtitle',
    chat: 'index.employer.support.subtitle.chat',
};

const Support: TranslatedComponent = ({ trls }) => {
    const contactPhones = useSelector((state) => state.contactPhones) || [];
    const regionsPhone = contactPhones.find((item) => item.postfix === 'other');
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const isZP = useIsZarplataPlatform();

    const openChat = () => {
        if (isZP) {
            (document.getElementsByClassName('UR_iWidgetKb')?.[0] as HTMLAnchorElement)?.click();
        }
    };

    return (
        <div className={styles.section}>
            <Column xs="4" s="8" m="12" l="14">
                <div className={styles.header}>{trls[TrlKeys.title]}</div>
                <VSpacing base={4} />
                <Text size={isXs ? TextSize.Medium : TextSize.ExtraLarge}>
                    {isZP && regionsPhone
                        ? formatToReactComponent(trls[TrlKeys.subtitle], {
                              '{0}': (
                                  <span className={styles.nowrap}>
                                      {`${regionsPhone.country} ${regionsPhone.city} ${regionsPhone.number}`}
                                  </span>
                              ),
                              '{1}': (
                                  <Link appearance={LinkAppearance.Pseudo} onClick={openChat}>
                                      {trls[TrlKeys.chat]}
                                  </Link>
                              ),
                          })
                        : trls[TrlKeys.subtitle]}
                </Text>
                {!isZP && (
                    <>
                        <VSpacing base={10} xs={9} />
                        <div
                            className={classnames(styles.contactList, {
                                [styles.contactListShort]: contactPhones.length < SHORT_LIST_THRESHOLD,
                            })}
                            data-qa="employer-index-support-contact-list"
                        >
                            {contactPhones.map((phone) => (
                                <Contact key={phone.postfix} {...phone} />
                            ))}
                        </div>
                    </>
                )}
            </Column>
        </div>
    );
};

export default translation(Support);
