import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H2Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import List from 'src/pages/AnonymousEmployer/components/Price/Card/List';
import ListItem from 'src/pages/AnonymousEmployer/components/Price/Card/ListItem';

import styles from './card.less';

interface CardProps {
    type: CardType;
    tiny?: boolean;
    header: string;
    cartOptions: string[];
    action: string;
    priceTitle?: string;
    toLink: string;
    clickEvent: string;
}

const Card: FC<CardProps & PropsWithChildren> = ({
    type,
    tiny,
    header,
    cartOptions,
    action,
    priceTitle,
    toLink,
    clickEvent,
}) => {
    const sendDV360Analytics = useDV360Analytics();

    const handleClick = () => {
        Analytics.sendHHEventButtonClick(clickEvent);
        if (type === CardType.Candidates) {
            sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_PRICE_CANDIDATES_BUTTON_CLICK');
        }
    };

    const priceTitleContainer = priceTitle ? (
        <div className={styles.priceTitle}>
            <Text>{priceTitle}</Text>
        </div>
    ) : null;

    return (
        <div className={classnames(styles.container, { [styles.tiny]: !!tiny })}>
            <div className={styles[`${type}Image`]} />
            <VSpacing base={6} xs={4} />
            <div className={styles.priceContainer}>{priceTitleContainer}</div>
            {priceTitleContainer && <VSpacing base={6} xs={4} />}
            <H2Section>{header}</H2Section>
            <VSpacing base={3} />
            <List>
                {cartOptions.map((text) => (
                    <ListItem key={text} text={text} />
                ))}
            </List>
            <VSpacing base={10} xs={6} />
            <Button
                data-qa={`${type}-price-card-action`}
                Element={Link}
                to={toLink}
                kind={ButtonKind.Primary}
                stretched
                onClick={handleClick}
            >
                {action}
            </Button>
        </div>
    );
};

export default Card;
