import { Fragment } from 'react';
import classnames from 'classnames';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import Subtitle from 'src/pages/AnonymousEmployer/components/Subtitle';

const TrlKeys = {
    1: {
        title: 'index.employer.step.1.title',
        description: 'index.employer.step.1.description',
    },
    2: {
        title: 'index.employer.step.2.title',
        description: 'index.employer.step.2.description',
    },
    3: {
        title: 'index.employer.step.3.title',
        description: 'index.employer.step.3.description',
    },
};

const STEPS = [1, 2, 3] as const;

const Steps: TranslatedComponent = ({ trls }) => {
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;

    return (
        <>
            {STEPS.map((step) => (
                <Fragment key={step}>
                    <Column xs="0" s="0" m="0" l="1" />
                    <Column xs="4" s="8" m="4" l="4">
                        <div
                            className={classnames('employer-index-step', {
                                'employer-index-step_arrow': step !== STEPS.length,
                            })}
                        >
                            <div className={classnames('employer-index-step-img', `employer-index-step-img_${step}`)} />
                            <Gap lTop mTop sLeft>
                                <Subtitle>{trls[TrlKeys[step].title]}</Subtitle>
                                <div className="employer-index-step-description">
                                    <Text
                                        size={isXs ? TextSize.Medium : TextSize.Large}
                                        importance={TextImportance.Secondary}
                                    >
                                        {trls[TrlKeys[step].description]}
                                    </Text>
                                </div>
                            </Gap>
                        </div>
                    </Column>
                </Fragment>
            ))}
        </>
    );
};

export default translation(Steps);
