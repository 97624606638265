import { FC } from 'react';

import styles from 'src/pages/AnonymousEmployer/components/Benefits/benefits-chart.less';

interface BenefitsChartProps {
    percent: number;
}

const SVG_SIZE = 100;
const STROKE_WIDTH = 17;

const BenefitsChart: FC<BenefitsChartProps> = ({ percent = 0 }) => {
    const radius = SVG_SIZE / 2;
    const insetRadius = radius - STROKE_WIDTH / 2;
    const strokeDasharray = Math.PI * (insetRadius * 2);
    const strokeDashoffset = (percent / 100) * strokeDasharray;

    return (
        <div className={styles.container}>
            <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className={styles.circleBackground}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray="0"
                    strokeDashoffset="0"
                    strokeWidth={STROKE_WIDTH}
                />
                <circle
                    className={styles.circlePercent}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    transform={`rotate(-90,${radius},${radius})`}
                    strokeWidth={STROKE_WIDTH}
                />
            </svg>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>{Math.round(percent)}%</div>
            </div>
        </div>
    );
};

export default BenefitsChart;
