import { FC } from 'react';

import { CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue, IconColor } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';

import styles from './list.less';

interface ListItemProps {
    text: string;
}

const ListItem: FC<ListItemProps> = ({ text }) => {
    return (
        <div className={styles.listItem}>
            <div className={styles.icon}>
                <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue initial={IconColor.Green60} />
            </div>
            <div className={styles.text}>
                <Text>{text}</Text>
            </div>
        </div>
    );
};

export default ListItem;
