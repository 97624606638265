import { FC, ReactElement } from 'react';

import { Avatar, AvatarIconStyle } from '@hh.ru/magritte-ui';
import Text from 'bloko/blocks/text';

import styles from './list.less';

interface ListItemProps {
    icon: ReactElement;
    text: string;
    style: AvatarIconStyle;
}

const ListItem: FC<ListItemProps> = ({ text, icon, style }) => {
    return (
        <div className={styles.listItem}>
            <div className={styles.icon}>
                <Avatar mode="icon" size={48} shapeCircle icon={icon} style={style} aria-label={text} />
            </div>
            <div className={styles.text}>
                <Text>{text}</Text>
            </div>
        </div>
    );
};

export default ListItem;
