import { useState } from 'react';

import Info from 'bloko/blocks/drop/Info';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsRabotaBy } from 'src/hooks/useSites';
import Subtitle from 'src/pages/AnonymousEmployer/components/Subtitle';

import BenefitsChart from 'src/pages/AnonymousEmployer/components/Benefits/BenefitsChart';

enum Benefits {
    Applicants = 'applicants',
    Vacancies = 'vacancies',
    Employers = 'employers',
}

const TrlKeys = {
    [Benefits.Applicants]: {
        title: 'index.employer.benefits.applicants.title',
        description: 'index.employer.benefits.applicants.description',
        number: 'index.employer.benefits.applicants.number',
        tip: 'index.employer.benefits.applicants.tip',
    },
    [Benefits.Vacancies]: {
        title: 'index.employer.benefits.vacancies.title',
        description: 'index.employer.benefits.vacancies.description',
        number: 'index.employer.benefits.vacancies.number',
        tip: 'index.employer.benefits.vacancies.tip',
    },
    [Benefits.Employers]: {
        title: 'index.employer.benefits.employers.title',
        description: 'index.employer.benefits.employers.description',
        number: 'index.employer.benefits.employers.number',
        tip: 'index.employer.benefits.employers.tip',
    },
};

const BenefitsPercentNumbersBY = {
    [Benefits.Applicants]: 38,
    [Benefits.Vacancies]: 71,
    [Benefits.Employers]: 94,
};

const BenefitsList: TranslatedComponent = ({ trls }) => {
    const [tipType, setTipType] = useState<Benefits | null>(null);
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const isRabotaBy = useIsRabotaBy();

    return (
        <div className="employer-index-benefits-list">
            {Object.keys(Benefits).map((benefit) => {
                const type = Benefits[benefit];
                return (
                    <div className="employer-index-benefits-item" key={type}>
                        {isRabotaBy ? (
                            <div className="employer-index-benefits-img">
                                <BenefitsChart percent={BenefitsPercentNumbersBY[type]} />
                            </div>
                        ) : (
                            <div className={`employer-index-benefits-img employer-index-benefits-img_${type}`} />
                        )}
                        <div>
                            <Subtitle>{trls[TrlKeys[type].title]}</Subtitle>
                            <div className="employer-index-benefits-description">
                                <Text size={isXs ? TextSize.Medium : TextSize.Large}>
                                    {formatToReactComponent(trls[TrlKeys[type].description], {
                                        '{0}': (
                                            <Info
                                                show={tipType === type}
                                                render={() => trls[TrlKeys[type].tip]}
                                                onClose={() => setTipType(null)}
                                            >
                                                <BlokoLink
                                                    onClick={() => setTipType(type)}
                                                    data-qa={`employer-index-${type}-tip-link`}
                                                    appearance={LinkAppearance.Pseudo}
                                                >
                                                    {trls[TrlKeys[type].number]}
                                                </BlokoLink>
                                            </Info>
                                        ),
                                    })}
                                </Text>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default translation(BenefitsList);
