import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import Subtitle from 'src/pages/AnonymousEmployer/components/Subtitle';

enum Benefits {
    Applicants = 'applicants',
    Vacancies = 'vacancies',
    Employers = 'employers',
}

const TrlKeys = {
    [Benefits.Applicants]: {
        title: 'index.employer.benefits.applicants.title',
    },
    [Benefits.Vacancies]: {
        title: 'index.employer.benefits.vacancies.title',
    },
    [Benefits.Employers]: {
        title: 'index.employer.benefits.employers.title',
    },
};

const BenefitsListZarplata: TranslatedComponent = ({ trls }) => {
    return (
        <div className="employer-index-benefits-list">
            {Object.keys(Benefits).map((benefit) => {
                const type = Benefits[benefit];
                return (
                    <div className="employer-index-benefits-item" key={type}>
                        <div
                            className={classnames(
                                'employer-index-benefits-img',
                                'employer-index-benefits-img_zarplata'
                            )}
                        />
                        <div>
                            <Subtitle>{trls[TrlKeys[type].title]}</Subtitle>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default translation(BenefitsListZarplata);
