import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import getConversionType from 'bloko/common/getConversionType';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import Title from 'src/pages/AnonymousEmployer/components/Title';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import SearchForm from 'src/pages/AnonymousEmployer/components/Search/SearchForm';

import styles from './search.less';

interface SearchProps {
    isRegularHighlight?: boolean;
}

const TrlKeys = {
    title: 'index.employer.find.title',
    subtitle: 'index.employer.search.subtitle',
    subtitleCounts: 'index.employer.search.subtitle.counts',
    subtitleApplicantMany: 'index.employer.search.subtitle.applicant.many',
    subtitleApplicantOne: 'index.employer.search.subtitle.applicant.one',
};

const Search: TranslatedComponent<SearchProps> = ({ trls, isRegularHighlight }) => {
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes) as number;
    const applicantCount = useSelector((state) => state.anonymousEmployer.applicantCount) as number;
    const isZarplata = useIsZarplataPlatform();

    const formattedResumeCount = NumberFormatter.format(`${resumeCount}`, { groupSeparator: NON_BREAKING_SPACE });
    const formattedApplicantCount = NumberFormatter.format(`${applicantCount}`, { groupSeparator: NON_BREAKING_SPACE });

    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const showResumeCount = !useIsRabotaBy();

    const renderText = (): string | null => {
        if (!showResumeCount) {
            return null;
        }

        if (resumeCount && applicantCount) {
            return format(trls[TrlKeys.subtitleCounts], {
                '{0}': formattedResumeCount,
                '{1}': `${formattedApplicantCount}${NON_BREAKING_SPACE}${getConversionType({
                    one: trls[TrlKeys.subtitleApplicantOne],
                    some: trls[TrlKeys.subtitleApplicantMany],
                    many: trls[TrlKeys.subtitleApplicantMany],
                    value: applicantCount,
                })}`,
            });
        }
        return trls[TrlKeys.subtitle];
    };
    return (
        <div
            className={classnames(styles.wrapper, {
                [styles.regular]: isRegularHighlight,
            })}
        >
            <ColumnsWrapper>
                <div className={styles.search}>
                    <div className={styles.header}>
                        <Title oneline>{trls[TrlKeys.title]}</Title>
                        {!isZarplata && (
                            <>
                                <VSpacing base={8} xs={5} />
                                <Text size={isXs ? TextSize.Medium : TextSize.Large}>{renderText()}</Text>
                            </>
                        )}
                    </div>
                    <VSpacing base={8} xs={5} />
                    <Column xs="4" s="8" m="12" l="14">
                        <SearchForm />
                    </Column>
                </div>
            </ColumnsWrapper>
        </div>
    );
};

export default translation(Search);
